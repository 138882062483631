import { useRef, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import history from 'modules/app/components/history'
import styled from 'styled-components'
import './profiles.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import DvrIcon from '@mui/icons-material/Dvr';
import Icon from '@mdi/react';
import { mdiBinoculars } from '@mdi/js';
import { mdiAccount } from '@mdi/js';
import { read_rb2b_profiles_info_from_aws_s3 } from 'modules/api/market-api'


const Profiles = (props: any) => {
	const [profiles_obj_arr, setProfilesObjArr] = useState<any>([])

    useEffect(() => {
        (async () => {
          if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
              const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
              if ( decoded.id == localStorage.getItem('userDbId') ) {
				const read_profiles_obj_arr_result = await read_rb2b_profiles_info_from_aws_s3()
				if (read_profiles_obj_arr_result.status === 'success') {
					let temp_profiles_obj_arr = read_profiles_obj_arr_result.result.profiles_obj_arr

                    temp_profiles_obj_arr = temp_profiles_obj_arr.filter((profile:any) => (profile.show_option === undefined || profile.show_option === true))

                    // console.log(temp_profiles_obj_arr)
					setProfilesObjArr(temp_profiles_obj_arr)

				} else if (read_profiles_obj_arr_result.status === 'fail') {
					history.push(`/admin/signin`)
				}
              } else {
                  history.push(`/admin/signin`)
              }
          } else {
              history.push(`/admin/signin`)
          }
        })()
          
      }, [])

    return (
        <>
            <div className='pfs_main_container'>
                <div className='pfs_main_wrapper'>
                    <div className='pfs_rounded'>

                        {
                            profiles_obj_arr?.length === 0 ? <></> : profiles_obj_arr?.map((ele:any, index:number) => {
                                return (
                                    <div className='pfs_flex_py_3' key={index}>
                                        <div className='pfs_p_3_w_2_3'>
                                            <div className='pfs_w_auto'>
                                                <div className='pfs_w_52px'>
                                                    {/* <img src="https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6708d744fdbd940001a1c9c1/picture" alt="Trustwell" /> */}
                                                    {/* <img src={ele.avatar_src} alt={ele.company_name} /> */}
                                                    {
                                                        ele.avatar_src !== "" ? 
                                                        <img src={ele.avatar_src} alt={ele.company_name} /> :
                                                        <CustomAccountCircleIcon className='custom_avatar_icon'/>
                                                    }
                                                </div>
                                            </div>

                                            <div className='pfs_grow_w_676'>
                                                <div className='pfs_w_full_flex'>
                                                    <div className='pfs_font_semibold'>
                                                        {/* <span className="pfs_cursor_pointer">Theresa Rex</span> */}
                                                        <span className="pfs_cursor_pointer">{ele.name}</span>
														&nbsp;
														{
															ele.linkedin_link === '' || ele.linkedin_link === undefined  ? <></>
															: 	<a href={ele.linkedin_link} target="_blank">
																	<i className="fa-brands fa-linkedin" style={{color: "#0076b2"}} aria-hidden="true"></i>
																</a>
														}
														
                                                    </div>
                                                    <div className='pfs_border_1'>

                                                    </div>
                                                    <div className='pfs_text_xs'>
                                                    <CustomLocationOnIcon className='pfs_text_icon'/>
                                                        {/* <i className="fa-sharp fa-sm fa-light fa-location-dot pfs_text_icon" aria-hidden="true"></i> */}
                                                        {/* <span className="pfs_text_8B8B88">Austin, TX</span> */}
                                                        <span className="pfs_text_8B8B88">{ele.location}</span>
                                                    </div>
                                                </div>

                                                <div className='pfs_flex_tems_start'>
                                                    <div className='pfs_flex_justify_center_item'>
                                                        <CustomBusinessCenterIcon className='pfs_brief_icon' />
                                                        {/* <i className="fa-regular fa-xs fa-briefcase pfs_brief_icon" aria-hidden="true"></i> */}
                                                    </div>

                                                    <div className='pfs_font_medium_activity'>
                                                        {ele.role}
                                                    </div>
                                                </div>

                                                {/* <div className='pfs_flex_items_center_gap_2'>
                                                    <div className='pfs_flex_justify_center_item'>
                                                        <CustomCorporateFareIcon className='pfs_buildings_icon'/>
                                                    </div>
                                                    <div className='pfs_font_medium_activity'>
                                                        {ele.company_name}
                                                    </div>
                                                </div> */}

												{
													ele.company_info_obj.company === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomCorporateFareIcon className='pfs_buildings_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Company</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.company}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.employees === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomGroupIcon className='pfs_user_group_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Employees</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.employees}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.revenue === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomGroupIcon className='pfs_user_group_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Revenue</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.revenue}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.category === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomLanguageIcon className='pfs_category_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Category</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.category}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.industry === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomLanguageIcon className='pfs_category_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Industry</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.industry}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.location === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomLocationOnIcon className='pfs_text_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Location</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.location}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.website === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomLinkIcon className='pfs_website_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_activity'>
																<div className='pfs_word_break_normal'>Website</div>
																<div>&nbsp;</div>
																<div>-</div>
																<div>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.website}</div>
															</div>
														</div>
												}

												{
													ele.company_info_obj.description === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomFormatQuoteIcon className='pfs_description_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
															<div className='pfs_font_medium_des'>
																<div className='pfs_word_break_normal'>Description</div>
																<div className='pfs_mobile_none'>&nbsp;</div>
																<div className='pfs_mobile_none'>-</div>
																<div className='pfs_mobile_none'>&nbsp;</div>
																<div className='pfs_word_break'>{ele.company_info_obj.description}</div>
															</div>
														</div>
												}

                                                <p className='pfs_desktop_none'></p>

												{
													// ele.company_info_obj === undefined ? <></>
													ele.activity_info_obj === undefined ? <></>
													: <div className='pfs_flex_items_center_gap_2'>
															<div className='pfs_flex_justify_center_item'>
																<CustomDvrIcon className='pfs_description_icon'/>
																{/* <i className="fa-regular fa-xs fa-buildings pfs_buildings_icon" aria-hidden="true"></i> */}
															</div>
                                                            <div style={{width: '100%', fontSize: '12px'}}>
                                                                <div className='pfs_font_medium_activity'>
                                                                    <div>Date and time</div>
                                                                    <div>&nbsp;</div>
                                                                    <div>-</div>
                                                                    <div>&nbsp;</div>
                                                                    <div className='pfs_word_break'>{ele.activity_info_obj.date_and_time}</div>
                                                                </div>
                                                                <div className='pfs_font_medium_activity'>
                                                                    <div>Page</div>
                                                                    <div>&nbsp;</div>
                                                                    <div>-</div>
                                                                    <div>&nbsp;</div>
                                                                    <div className='pfs_word_break'>{ele.activity_info_obj.page}</div>
                                                                </div>
                                                                <div className='pfs_font_medium_activity'>
                                                                    <div>Referer</div>
                                                                    <div>&nbsp;</div>
                                                                    <div>-</div>
                                                                    <div>&nbsp;</div>
                                                                    <div className='pfs_word_break'>{ele.activity_info_obj.referrer}</div>
                                                                </div>
                                                                <div className='pfs_font_medium_activity'>
                                                                    <div>Title</div>
                                                                    <div>&nbsp;</div>
                                                                    <div>-</div>
                                                                    <div>&nbsp;</div>
                                                                    <div className='pfs_word_break'>{ele.activity_info_obj.title}</div>
                                                                </div>
                                                            </div>
															
														</div>
												}

                                            </div>
                                        </div>

                                        <div className='pfs_p_3_w_1_3'>
                                            

                                            {
                                                ele.emails.length > 0 && ele.emails.map((email_ele: any, email_index: number) => {
                                                    return (
                                                        <div className='pfs_flex_items_start_gap' key={email_index}>
                                                            <div className='pfs_flex_justify_center_item'>
                                                                <CustomMailOutlineIcon className='pfs_envelope' />
                                                                {/* <i className="fa-regular fa-xs fa-envelope pfs_envelope" aria-hidden="true"></i> */}
                                                            </div>

                                                            <div className="pfs_font_medium_text_xs">
                                                                {/* theresa.rex@personiv.com */}
                                                                {email_ele}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

											{
												ele.linkedin_link === '' || ele.linkedin_link === undefined  ? <></>
													:<a href={ele.linkedin_link} target="_blank" style={{display: 'flex', textDecoration: 'none', color: '#131310'}}>
														<div className='pfs_flex_items_start_gap'>
															<div className='pfs_flex_justify_center_item'>
																<i className="fa-brands fa-linkedin" style={{color: "#0076b2"}} aria-hidden="true"></i>
															</div>
															<div className="pfs_font_medium_text_xs">
																{ele.linkedin_link}
															</div>
														</div>
													</a>
											}
											
                                            <div className='pfs_flex_items_start_gap'>
                                                <div className='pfs_flex_justify_center_item'>
                                                    <CustomIcon path={mdiBinoculars} className='pfs_binoculars'/>
                                                    {/* <i className="fa-regular fa-xs fa-binoculars pfs_binoculars" aria-hidden="true"></i> */}
                                                </div>

                                                <div className="pfs_font_medium_text_xs">
                                                {/* Last seen - 10/30/2024 12:36:00 PM EDT */}
                                                {ele.last_seen}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

{profiles_obj_arr.length}
                    </div>
                </div>
            </div>
        </>                                                             
    );
};

const CustomLocationOnIcon = styled(LocationOnIcon)`
    &.pfs_text_icon {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomBusinessCenterIcon = styled(BusinessCenterIcon)`
    &.pfs_brief_icon {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomCorporateFareIcon = styled(CorporateFareIcon)`
    &.pfs_buildings_icon {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomMailOutlineIcon = styled(MailOutlineIcon)`
    &.pfs_envelope {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomAccountCircleIcon = styled(AccountCircleIcon)`
    &.custom_avatar_icon {
       width: 52px;
        height: 52px;
        color: #acaca9;
    }
`
const CustomGroupIcon = styled(GroupIcon)`
    &.pfs_user_group_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomLanguageIcon = styled(LanguageIcon)`
    &.pfs_category_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomLinkIcon = styled(LinkIcon)`
    &.pfs_website_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomFormatQuoteIcon = styled(FormatQuoteIcon)`
    &.pfs_description_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomDvrIcon = styled(DvrIcon)`
    &.pfs_description_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomIcon = styled(Icon)`
    &.pfs_binoculars {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
        width: 16px;
        height: 16px;
    }
`

export default Profiles