import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { add_item_email } from 'modules/api/market-api';


interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function AddEmailDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_item_email, setEditedItemEmail ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    if(props.add_item_index !== -1) {
      // setEditedItemEmail(props.edited_item_email)
    }
  }, [props.add_item_index])

  const handleClose = () => {
    props.setAddItemIndex(-1)
    // props.setEditedItemEmailIndex(-1)
    // props.setEditedItemEmail('')
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        // console.log(edited_item_email, props.add_item_index)
        
        let update_result = await add_item_email(edited_item_email, props.add_item_index)

        // console.log(update_result)
        if ( update_result.result.status === 'success' ) {
          props.setProfilesObjArr((prev: any[]) => {
            let next = [...prev]
            let temp_emails = JSON.parse(JSON.stringify(next[props.add_item_index].emails))
            temp_emails.push(edited_item_email)
            
            next[props.add_item_index] = Object.assign(next[props.add_item_index], {emails: temp_emails})

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
          props.setAddItemIndex(-1)
          // props.setEditedItemEmailIndex(-1)
          // props.setEditedItemEmail('')
        } else if ( update_result.result.status === 'fail' ) {
          setIsLoading(false)
          props.setOpen(false);
          props.setAddItemIndex(-1)
          // props.setEditedItemEmailIndex(-1)
          // props.setEditedItemEmail('')
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItemEmail(e.target.value)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth >
      {
        isLoading === true ? 
        <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              // onClick={handleClose}
          >
              <CircularProgress color="inherit"/>
              <p>&nbsp;&nbsp; Saving Data ...</p>
        </Backdrop> : <></>
      }
      
        <DialogTitle>Add Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            multiline
            rows={1}
            maxRows={1}
            value={edited_item_email}
            onChange={handleEmailChange}
            // onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
